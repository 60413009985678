import {Navigate} from "react-router-dom"
import {jwtDecode} from "jwt-decode"
import api from "../components/api"
import { REFRESH_TOKEN, ACCESS_TOKEN } from "../constants";
import { useState, useEffect } from "react";

function ProtectedRoute({children}) {
    const [isAuthorised, setIsAuthorized] = useState(null)

    useEffect(() => {
        auth().catch(() => setIsAuthorized(false))
    }, [])

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        try {
            const response = await api.post("/v1/token/refresh/", {
                refresh: refreshToken,
            });
            if (response.status === 200){
                localStorage.setItem(ACCESS_TOKEN, response.data.access)
                setIsAuthorized(true)
            }
            else {
                setIsAuthorized(false)
            }
        }catch (error) {
            console.log(error);
            setIsAuthorized(false);
        }
    };

    const auth = async () => {
        const  token = localStorage.getItem(ACCESS_TOKEN);
        if (!token) {
            setIsAuthorized(false);
            return
        }
        const user = jwtDecode(token);
        const isExpired = user.exp;
        const now = Date.now() / 1000;

        if (isExpired < now){
            await refreshToken();
        }
        else {
            setIsAuthorized(true);
        }

    }

    if (isAuthorised === null) {
        return <div>Loading...</div>
    }

    return isAuthorised ?  children : <Navigate to="/login" />

}

export default ProtectedRoute
