import React, { useEffect } from "react";

function NotFound() {
    useEffect(() => {
        const socket = new WebSocket("https://api.vynechain.org/ws/vyne-gaming/vyne-duelLDB/");

        socket.onopen = function () {
            console.log("WebSocket connection established");

            // Send the initial "list" action to the server once the connection is open
            socket.send(
                JSON.stringify({
                    action: "list",
                    request_id: new Date().getTime(),
                })
            );
        };

        socket.onmessage = function (e) {
            try {
                console.log("WebSocket message received");
                const allData = JSON.parse(e.data);

                if (allData.action === "list") {
                    console.log("List data:", allData.data);
                } else if (allData.action === "update") {
                    console.log("Update data:", allData.data);
                } else if (allData.action === "create") {
                    console.log("Create data:", allData.data);
                } else {
                    console.warn("Unknown action:", allData.action);
                }
            } catch (error) {
                console.error("Error processing WebSocket message:", error);
            }
        };

        socket.onerror = function (error) {
            console.error("WebSocket error:", error);
        };

        socket.onclose = function () {
            console.log("WebSocket disconnected");
        };

    }, []); // Empty dependency array ensures the WebSocket connection is created only once

    return (
        <div className="container">
            <h1>Error 404: Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFound;
