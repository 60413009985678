import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer section-padding">
        <div className="footer-links">
          <div className="footer-links-div">
            <h4>Community</h4>
            <a href="https://t.me/vynechainglobal">
              <p>Telegram</p>
            </a>
          </div>
          <div className="footer-links-div">
            <h4>Company</h4>
            <a href="/about">
              <p>About</p>
            </a>
            <a href="/faq">
              <p>Frequently Asked Questions</p>
            </a>
            <a href="/policy">
              <p>Policy</p>
            </a>
            <a href="/contact">
              <p>Contact Us</p>
            </a>
          </div>
          <div className="footer-links-div">
            <h4>Media</h4>
            <a href="https://t.me/officialvynechain">
              <p>Telegram</p>
            </a>
            <a href="https://x.com/vynechain">
              <p>Twitter(X)</p>
            </a>
            <a href="https://www.youtube.com/@Vynechain">
              <p>Youtube</p>
            </a>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="footer-below">
        <div className="footer-copyright">
          <p>@{new Date().getFullYear()} Vynechain. All rights reserved</p>
        </div>
        <div className="footer-below-links">
          <a href="/terms">
            <div>
              <p>Terms & Conditions</p>
            </div>
          </a>
          <a href="/privacy">
            <div>
              <p>Privacy</p>
            </div>
          </a>
          <a href="/security">
            <div>
              <p>Security</p>
            </div>
          </a>
          <a href="/cookie">
            <div>
              <p>Cookie Declaration</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
