const Policy = () => {
  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "1000px",
        margin: "0 auto",
      }}>
      <h1 style={{ color: "white" }}>Terms of Service</h1>

      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>1. Acceptance of Terms</h2>
        <p style={{ marginBottom: "10px" }}>
          Vynechain, its parent and affiliates provides a platform for mining
          the cryptocurrency VYN, for enabling individual users to contribute to
          the security and community of VYNECHAIN Network, and for allowing
          ordinary websites and applications to interact with blockchains to
          build a blockchain ecosystem.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Please read these Terms of Service carefully before using the Service.
          By using or otherwise accessing the Services, or clicking to accept or
          agree to these Terms where that option is made available, you (1)
          accept and agree to these Terms (2) consent to the collection, use,
          disclosure and other handling of information as described in our
          Privacy Policy (3) agree to and accept any additional terms, rules and
          conditions of participation issued by vynechain from time to time. If
          you do not agree to the Terms, then you may not access or use the
          Content or Services.
        </p>
      </section>

      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px", color: "white" }}>
          2. Modification of Terms of Service
        </h2>
        <p style={{ marginBottom: "10px" }}>
          Binding arbitration and waiver of class action rights, Vynechain
          reserves the right, at its sole discretion, to modify or replace the
          Terms of Service at any time. The most current version of these Terms
          will be posted on our Site. You are responsible for reviewing and
          becoming familiar with any such modifications. Use of the Services by
          you after any modification to the Terms constitutes your acceptance of
          the Terms of Use as modified.
        </p>
      </section>

      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>3. Eligibility</h2>
        <p style={{ marginBottom: "10px" }}>
          You hereby represent and warrant that you are fully able and competent
          to enter into the terms, conditions, obligations, affirmations,
          representations and warranties set forth in these Terms; that you are
          using the Site solely for your personal use; and that you will abide
          by and comply with these Terms. This Site is not directed to users
          under the age of 13.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Vynechain is a global platform and by accessing the Content or
          Services, you are representing and warranting that, you are of the
          legal age of majority in your jurisdiction as is required to access
          such Services and Content and enter into arrangements as provided by
          the Service. You further represent that you are otherwise legally
          permitted to use the Service in your jurisdiction, including owning
          cryptographic tokens of value, and interacting with the Services or
          Content in any way. You further represent you are responsible for
          ensuring compliance with the laws of your jurisdiction and acknowledge
          that Vynechain is not liable for your compliance with such laws.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>
          4. Account Password and Security
        </h2>
        <p style={{ marginBottom: "10px" }}>
          When setting up an account within Vynechain, you will be responsible
          for keeping your passwords, which you use to sign into your VYNE
          account, and which we use to keep third-party accounts safe. Vynechain
          encrypts this information locally with a random keyword we provide and
          securely store on our servers for your convenience. By using the
          Service, You agree that (a) all registration information you provide
          to us is accurate, complete and correct; (b) all registration
          information you provide is governed by the terms of our Privacy Policy
          at https://vynechain.org/privacy (c) you will have two-way
          authentication login for any third-party service used to access the
          Service, e.g. Facebook; (d) you will never use the same password for
          the Vynechain- connected, Facebook account for any account or purpose
          outside of this Service; (e) you will keep your password to the
          third-party services (Facebook) confidential and not share them with
          anyone else; (f) you will immediately notify Vynechain of any
          unauthorized use of your account or breach of security in the
          third-party services (Facebook). Vynechain cannot and will not be
          liable for any loss or damage arising from your failure to comply with
          this section.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>5. Submissions</h2>
        <p style={{ marginBottom: "10px" }}>
          If you post material to the Site, or otherwise make (or allow any
          third party to make) material available by means of the Site (any such
          material, “User Content”), you are entirely responsible for the
          content of, and any harm resulting from, such User Content. That is
          the case regardless of whether your User Content constitutes text,
          graphics, an audio file, or computer software.
        </p>
        <p style={{ marginBottom: "10px" }}>
          By making User Content available, you represent and warrant that:
        </p>
        <p style={{ marginBottom: "10px" }}>
          Your User Content does not contain or install any viruses, worms,
          malware, Trojan horses or other harmful or destructive content;{" "}
        </p>
        <p style={{ marginBottom: "10px" }}>
          Your User Content is not spam and does not contain unethical or
          unwanted commercial content designed to drive traffic to third party
          sites or boost the search engine rankings of third party sites, or to
          further unlawful acts (such as phishing) or mislead recipients as to
          the source of the material (such as spoofing);
        </p>
        <p style={{ marginBottom: "10px" }}>
          You will post only User Content that is relevant to Vynechain
          ecosystem and at a rate and volume that does not hinder other Users'
          ability to use our platform;
        </p>
        <p style={{ marginBottom: "10px" }}>
          Your User Content is not libelous or defamatory, does not contain
          threats or incite violence towards individuals or entities, and does
          not violate the privacy or publicity rights of any third party.
        </p>
        <p style={{ marginBottom: "10px" }}>
          By submitting User Content to Vynechain for inclusion on the Site, you
          grant Vynechain a world-wide, perpetual, royalty-free, and
          non-exclusive license to reproduce, modify, adapt, and publish your
          User Content in connection with the Site. You represent and warrant
          that (a) you own and control all of the rights to the User Content
          that you post or you otherwise have the right to post to the Site; (b)
          your User Content is accurate and not misleading; and (c) use and
          posting of your Content does not violate these Terms of Service and
          will not violate any rights of or cause injury to any person or
          entity.
        </p>
        <p style={{ marginBottom: "10px" }}>
          Without limiting any of these representations or warranties, Vynechain
          has the right (though not the obligation), in Vynechain's sole
          discretion, to terminate or deny access to and use of the Site to any
          individual or entity for any reason.
        </p>
      </section>

      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>6. Acceptable Use</h2>
        <p style={{ marginBottom: "10px" }}>
          Except as expressly provided in these Terms, you agree that you will
          not: (i) copy the Services or Content or any portion thereof; (ii)
          distribute, modify, adapt, translate, reverse engineer, decompile,
          disassemble, create derivative works based on, print any part of, or
          otherwise attempt to discover the source code, compiled executables,
          intermediate files, or underlying structure, ideas, know-how or
          algorithms relating in any way to the Services or Content; (iii) use
          the Services to send or store infringing, obscene, threatening,
          libelous, or otherwise unlawful material, including material that
          violates third party rights; (vi) use the Services to create, use,
          send, store, or run material containing software viruses, worms,
          Trojan horses or otherwise engage in any malicious act or disrupt the
          security, integrity or operation of the Services; (vii) use the
          Services in any manner that violates any applicable foreign, federal,
          state or local law, rule or regulation; (viii) use the Services in any
          manner that violates any third party rights or the terms of this
          Agreement; (ix) use scripting, computer programs or third-party
          scripting services to create fake accounts, to receive vyne coins
          intended for accounts held by real human beings, or spam the chat
          rooms on the Site; (x) sell or transfer user accounts to another
          individual or entity; or (xi) participate in the sale of VYNE
          unauthorized by Vynechain or its affiliates. The violation of these
          terms may result in Vynechain sole discretion, terminating or denying
          access to and use of the Site, freezing accounts, removing or
          canceling previously awarded VYNE of any individual or entity.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>
          7. Representations, Warranties and Risks
        </h2>
        <p style={{ marginBottom: "10px" }}>
          7.1. Warranty Disclaimer: <br /> You expressly understand and agree
          that your use of the Service is at your sole risk. The Service and the
          Content are provided on an "AS IS" and "as available" basis, without
          warranties of any kind, either express or implied, including, without
          limitation, implied warranties of merchantability, fitness for a
          particular purpose or non-infringement. You acknowledge that VyneChain
          has no control over, and no duty to take any action regarding: which
          users gain access to or use the Service; what effects the Content may
          have on you; how you may interpret or use the Content; or what actions
          you may take as a result of having been exposed to the Content. You
          release VyneChain from all liability arising from your access to or
          use of the Service or the Content. VyneChain makes no representations
          concerning any Content contained in or accessed through the Service,
          and VyneChain will not be responsible or liable for the accuracy,
          copyright compliance, legality or decency of material contained in or
          accessed through the Service.
        </p>
        <p style={{ marginBottom: "10px" }}>
          7.2 Sophistication and Risk of Cryptographic Systems: <br /> By
          utilizing the Service or interacting with the Content or platform in
          any way, you represent that you understand the inherent risks
          associated with cryptographic systems; and warrant that you have an
          understanding of the usage and intricacies of native cryptographic
          tokens, like VYNE, smart contract based tokens such as those that are
          a part of the VYNECHAIN ecosystem, and blockchain-based software
          systems.
        </p>
        <p style={{ marginBottom: "10px" }}>
          7.3 Risk of Regulatory Actions in One or More Jurisdictions: <br />
          VyneChain and the crypto assets you store on VyneChain could be
          impacted by one or more regulatory inquiries or regulatory action,
          which could impede or limit the ability of VyneChain to continue to
          develop, or which could impede or limit your ability to access or use,
          the Service.
        </p>
        <p style={{ marginBottom: "10px" }}>
          7.4 Risk of Weaknesses or Exploits in the Field of Cryptography:{" "}
          <br />
          You acknowledge and understand that Cryptography is a progressing
          field. Advances in code cracking or technical advances such as the
          development of quantum computers may present risks to
          cryptocurrencies, the Services or the Content, which could result in
          the theft or loss of your cryptographic tokens or property. To the
          extent possible, VyneChain intends to update the protocol underlying
          Services to account for any advances in cryptography and to
          incorporate additional security measures. VyneChain does not guarantee
          or otherwise represent full security of the system. By using the
          Service or accessing Content, you acknowledge these inherent risks.
        </p>
        <p style={{ marginBottom: "10px" }}>
          7.5 Volatility of Crypto Currencies: <br />
          <br />
          You understand blockchain technologies and associated currencies or
          tokens are highly volatile due to many factors including but not
          limited to adoption, speculation, technology and security risks. You
          also acknowledge that after the Mainnet of VYN becomes live or VYN is
          listed in exchanges, VYN will be of similar volatility and subject to
          the same factors faced by other cryptocurrencies. You also acknowledge
          that the cost of transacting on such technologies is variable and may
          increase at any time causing impact to any activities taking place on
          the blockchains. You acknowledge these risks and represent that
          VyneChain cannot be held liable for such fluctuations or increased
          costs.
        </p>
        <p style={{ marginBottom: "10px" }}>
          7.6 Application Security: <br />
          <br />
          You acknowledge that blockchain applications are code subject to flaws
          and acknowledge that you are solely responsible for evaluating any
          code provided by the Services or Content and the trustworthiness of
          any third-party websites, products, smart-contracts, or Content you
          access or use through the Service. You further expressly acknowledge
          and represent that blockchain applications can be written maliciously
          or negligently, that VyneChain cannot be held liable for your
          interaction with such applications and that such applications may
          cause the loss of property or even identity. This warning and others
          later provided by VyneChain in no way evidence or represent an
          on-going duty to alert you to all of the potential risks of utilizing
          the Service or Content.
        </p>
      </section>

      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>8. Indemnity</h2>
        <p style={{ marginBottom: "10px" }}>
          You agree to release and to indemnify, defend and hold harmless
          VyneChain and its parents, subsidiaries, affiliates and agencies, as
          well as the officers, directors, employees, shareholders and
          representatives of any of the foregoing entities, from and against any
          and all losses, liabilities, expenses, damages, costs (including
          attorneys' fees and court costs), claims or actions of any kind
          whatsoever arising or resulting from your use of the Service other
          than as expressly authorized by and pursuant to the terms of these
          Terms of Service, your violation of these Terms of Service, and any of
          your acts or omissions that implicate publicity rights, defamation or
          invasion of privacy. VyneChain reserves the right, at its own expense,
          to assume exclusive defense and control of any matter otherwise
          subject to indemnification by you and, in such case, you agree to
          cooperate with VyneChain in the defense of such matter.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>9. Limitation of Liability</h2>
        <p style={{ marginBottom: "10px" }}>
          YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR
          USE OF THE SITE AND SERVICE. YOU ACKNOWLEDGE AND AGREE THAT ANY
          INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE SITE AND
          SERVICE MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY
          UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE
          SITE AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH, YOU UNDERSTAND
          AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
          NEITHER VYNECHAIN NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU
          FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT
          LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
          TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER DAMAGES BASED ON CONTRACT,
          TORT, STRICT LIABILITY OR ANY OTHER THEORY (EVEN IF VYNECHAIN HAD BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SITE
          OR SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR SERVICE;
          UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
          STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE OR SERVICE; ANY
          ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND
          TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING
          PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS,
          LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR
          SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT
          PARTICIPATION IN THE SERVICE); ANY INJURY OR DAMAGE TO COMPUTER
          EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR SERVICE OR ANY OTHER
          WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO,
          IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR
          INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER
          ERRORS, OR ANY COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO
          THE SITE OR SERVICE.
        </p>
        <p style={{ marginBottom: "10px" }}>
          VyneChain assumes no responsibility, and shall not be liable for, any
          damages to, or viruses that may infect, your computer,
          telecommunication equipment, or other property caused by or arising
          from your access to, use of, or browsing the Site or your downloading
          of any materials, from the Site.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>10. Our Proprietary Rights</h2>
        <p style={{ marginBottom: "10px" }}>
          The Site, the Service, all functionality of the Site and Service, and
          all Content and all intellectual property are owned by VyneChain or
          its licensors. You acknowledge and agree that the Service contains
          proprietary and confidential information that is protected by
          applicable intellectual property and other laws. Except as expressly
          authorized by VyneChain, you agree not to copy, modify, rent, lease,
          loan, sell, distribute, perform, display or create derivative works
          based on the Service or Content, in whole or in part.
        </p>
        <p style={{ marginBottom: "10px" }}>
          The trademarks VYNECHAIN, VYNE, VYN, VSD and the VYN Design (the
          "Trademarks) are trademarks of VyneChain and its parent Company. You
          may not use the trademarks without the prior written permission of
          VyneChain. Any permitted use of the Trademarks must be pursuant to and
          in compliance with the Vynechain Trademark Guidelines
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>11. Links</h2>
        <p style={{ marginBottom: "10px" }}>
          The Service provides, or third parties may provide, links to other
          World Wide Web or accessible sites, applications or resources. Because
          VyneChain has no control over such sites, applications and resources,
          you acknowledge and agree that VyneChain is not responsible for the
          availability of such external sites, applications or resources, and
          does not endorse and is not responsible or liable for any content,
          advertising, products or other materials on or available from such
          sites or resources. You further acknowledge and agree that VyneChain
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any such content, goods or services available on
          or through any such site or resource.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>12. Termination and Suspension</h2>
        <p style={{ marginBottom: "10px" }}>
          VyneChain's sole discretion, may terminate or suspend all or part of
          the Service and your VyneChain access immediately, without prior
          notice or liability, if you breach any of the terms or conditions of
          the Terms. Upon termination of your access, your right to use the
          Service will immediately cease.
        </p>
        <p style={{ marginBottom: "10px" }}>
          The following provisions of the Terms survive any termination of these
          Terms: <br /> INDEMNITY; WARRANTY DISCLAIMERS; LIMITATION OF
          LIABILITY; OUR PROPRIETARY RIGHTS; LINKS; TERMINATION; NO THIRD PARTY
          BENEFICIARIES; BINDING ARBITRATION AND; GENERAL INFORMATION.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>
          13. No Third Party Beneficiaries
        </h2>
        <p style={{ marginBottom: "10px" }}>
          You agree that, except as otherwise expressly provided in these Terms,
          there shall be no third party beneficiaries to the Terms.
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>14. General Information</h2>
        <p style={{ marginBottom: "10px" }}>
          14.1 Entire Agreement: <br /> You agree that, except as otherwise
          expressly provided in these Terms, there shall be no third party
          beneficiaries to the Terms.
        </p>
        <p style={{ marginBottom: "10px" }}>
          14.2 Waiver and Severability of Terms: <br /> The failure of VyneChain
          to exercise or enforce any right or provision of the Terms shall not
          constitute a waiver of such right or provision. If any provision of
          the Terms is found by an arbitrator or court of competent jurisdiction
          to be invalid, the parties nevertheless agree that the arbitrator or
          court should endeavor to give effect to the parties' intentions as
          reflected in the provision, and the other provisions of the Terms
          remain in full force and effect
        </p>
        <p style={{ marginBottom: "10px" }}>
          14.3 Statute of Limitations: <br /> You agree that any claim or cause
          of action arising out of or related to the use of the Service or the
          Terms must be filed within one (1) year after such claim or cause of
          action arose, or another period as provided under statute or law, or
          be forever barred.
        </p>
        <p style={{ marginBottom: "10px" }}>
          14.4 Entire Agreement <br /> This Agreement contains the complete and
          entire understanding between you and Us regarding your access to and
          use of the Services. The Agreement supersedes all prior agreements
          between you and Us regarding Your access to and use of the Services.
        </p>
        <p style={{ marginBottom: "10px" }}>
          14.5 Section Titles The section titles in this Agreement are for
          convenience only and have no legal or contractual effect.
        </p>
        <p style={{ marginBottom: "10px" }}>
          14.6 Communications: <br />
          Users with questions, complaints or claims with respect to the Site or
          Service may contact us using the relevant contact information <br />
          Support@vynechain.org
        </p>
      </section>
      <section style={{ marginBottom: "20px", lineHeight: "1.5" }}>
        <h2 style={{ marginBottom: "10px" }}>15. In-App Transfers</h2>
        <p style={{ marginBottom: "10px" }}>
          If you're enabled to perform in-app transfers in our mobile
          applications or to transfer VYN OR VSD, you also agree to the
          additional terms and conditions below.
        </p>
        <p style={{ marginBottom: "10px" }}>
          You agree not to offer any sales of VYN or VSD any derivatives of
          those units (e.g., “futures”) by you for other currencies prior to the
          launch of VYNECHAIN P2P MARKET
        </p>
        <p style={{ marginBottom: "10px" }}>
          You agree that you and only you are the exclusive holder of this
          account/wallet and the units of VYN associated with your
          account/wallet and that you will not transfer your account/wallet to
          another individual, group, or organization, or grant access to your
          account/wallet to another person.
        </p>
        <p style={{ marginBottom: "10px" }}>
          You agree not to engage in any illicit and / or illegal transactions
          using VYN, or in any activities.
        </p>
      </section>

      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <p>For questions or concerns, contact us at: Support@vynechain.org</p>
      </div>
    </div>
  );
};

export default Policy;
