import React from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa"; // Import the avatar icon
import { vchaincustom } from "../Assets"; // Updated import
import "../styles/Navbar.css";

function Navbar() {
  return (
    <div className="navbar-container">
      <div className="navbar">
        <img className="icon" src={vchaincustom} alt="customlogo" />
        <input type="checkbox" id="sidebar-active" />
        <label htmlFor="sidebar-active" className="open-sidebar-btn">
          <IoMenu />
        </label>
        <label id="overlay" htmlFor="sidebar-active"></label>
        <div className="links-container">
          <label htmlFor="sidebar-active" className="close-sidebar-btn">
            <IoClose />
          </label>

          <a href="/profile" className="avatar-icon">
            <FaUserCircle />
          </a>
          <a className="active" href="/">
            Home
          </a>
          <a href="/policy">Policy</a>
          <a href="/faq">FAQ</a>
          <a href="/about">About Us</a>
          <a href="/contact">Contact Us</a>
          <a className="action" href="/register">
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
